import Link from "../components/Link/Link"

const Cookie = () => {
  return (
    <div className="App page-width px-6">
      <div className="mt-4 mb-12">
        <Link url="/" text="Back to home" leftArrow={true} />
      </div>
      <h1 className="font-bold text-3xl mb-8">Cookie Policy</h1>
      <h2 className="font-bold text-2xl my-4">1. Introduction</h2>
      <p>This Cookie Policy explains how Typesmith.Ai, operated by Venture Stream Ltd ("we", "us", "our"), uses cookies and similar technologies to recognize you when you visit our website at www.typesmith.ai. It explains what these technologies are and why we use them, as well as your rights to control our use of them.</p>
      <h2 className="font-bold text-2xl my-4">2. What are cookies?</h2>
      <p>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners to make their websites work, or to work more efficiently, as well as to provide reporting information.</p>
      <h2 className="font-bold text-2xl my-4">3. Why do we use cookies?</h2>
      <p>We use first-party and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our website to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies enable us to track and target the interests of our users to enhance the experience on our website. Third parties serve cookies through our website for advertising, analytics, and other purposes. This is described in more detail below.</p>
      <h2 className="font-bold text-2xl my-4">4. Types of cookies we use</h2>
      <p>Essential Cookies: These cookies are strictly necessary to provide you with services available through our website and to use some of its features, such as access to secure areas.</p>
      <p>Analytics and Performance Cookies: These cookies are used to collect information about traffic to our website and how users use our site. The information gathered does not identify any individual visitor.</p>
      <p>Functionality Cookies: These cookies allow our website to remember choices you make and provide enhanced, more personal features.</p>
      <h2 className="font-bold text-2xl my-4">5. How can you control cookies?</h2>
      <p>You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide you with services.</p>
      <p>Additionally, most browsers allow you to control cookies through the browser settings. As the means by which you can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your browser's help menu for more information.</p>
      <h2 className="font-bold text-2xl my-4">6. Changes to the Cookie Policy</h2>
      <p>We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal, or regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.</p>
      <h2 className="font-bold text-2xl my-4">7. Contact Us</h2>
      <p>If you have any questions about our use of cookies or other technologies, please email us at hi@venturestream.co.uk.</p>
      <p>This Cookie Policy provides the framework for managing cookie preferences and adheres to the comprehensive approach outlined in the detailed Terms and Conditions and Privacy Policy for Typesmith.Ai. Remember to review and update the Cookie Policy regularly to reflect any changes in cookie usage or regulatory requirements.</p>
    </div>
  )
}

export default Cookie