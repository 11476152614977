import Link from "../components/Link/Link"

const Privacy = () => {
  return (
    <div className="App page-width px-6">
      <div className="mt-4 mb-12">
        <Link url="/" text="Back to home" leftArrow={true} />
      </div>
      <h1 className="font-bold text-3xl mb-8">Privacy Policy</h1>
      <h2 className="font-bold text-2xl my-4">1. Introduction</h2>
      <p>This Privacy Policy explains how Typesmith.Ai ("we", "us", "our") collects, uses, and shares your personal information when you use our Service.</p>
      <h2 className="font-bold text-2xl my-4">2. Information We Collect</h2>
      <p>We collect and process information you provide directly to us when you use our Service. This includes product details, images, and any other information necessary for generating content. We may also collect information about your usage of our Service and information from third parties, such as OpenAI, for content generation purposes.</p>
      <h2 className="font-bold text-2xl my-4">3. Use of Your Information</h2>
      <p>To provide, maintain, and improve our Service, including to generate and manage your content.</p>
      <p>To communicate with you, including responding to your inquiries and providing customer support.</p>
      <p>For research and development purposes to enhance our Service.</p>
      <p>To monitor the usage of our Service and detect, prevent, and address technical issues.</p>
      <h2 className="font-bold text-2xl my-4">4. Sharing Your Information</h2>
      <p>We do not sell your personal information to third parties. We may share your information with third parties to provide and improve our Service, comply with the law, protect the rights, property, or safety of us, our users, or others, and with your consent.</p>
      <h2 className="font-bold text-2xl my-4">5. Data Security</h2>
      <p>We implement measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure.</p>
      <h2 className="font-bold text-2xl my-4">6. Changes to This Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
      <h2 className="font-bold text-2xl my-4">7. Contact Us</h2>
      <p>For more information about our privacy practices, or if you have questions or would like to make a complaint, please contact us at <a href="mailto:hi@venturestream.co.uk">hi@venturestream.co.uk</a>.</p>
    </div>
  )
}

export default Privacy