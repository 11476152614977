const Header = () => {

  const scrollToDiv = (e) => {
    e.preventDefault();
    let _this = e.target;
    const id = _this.getAttribute('href');
    const yOffset = -150; 
    const element = document.querySelector(id);
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({top: y, behavior: 'smooth'});
  }

  return (
    <header className="p-6 border-b border-[#EFEFEF] flex items-center justify-between sticky top-0 left-0 right-0 z-10 bg-white">
      <h1 className="text-4xl">
        <svg xmlns="http://www.w3.org/2000/svg" width="182.683" height="40" viewBox="0 0 182.683 40" className="w-32 h-12 lg:w-auto lg:h-auto">
          <g id="Group_74" dataname="Group 74" transform="translate(-831 -873)">
            <path id="Path_83" dataname="Path 83" d="M146.683,17.753V35.837h5.861V35.5q0-4.444,0-8.886a6.786,6.786,0,0,1,.6-3.011,2.787,2.787,0,0,1,2.737-1.685,2.272,2.272,0,0,1,2.548,1.988,9.329,9.329,0,0,1,.228,2.022c.023,3.208.01,6.416.01,9.625v.285h5.918c0-.125,0-.231,0-.337,0-3.11-.008-6.22.013-9.33a5.742,5.742,0,0,1,.747-2.9,2.565,2.565,0,0,1,1.261-1.127,3.63,3.63,0,0,1,1.767-.2,2.167,2.167,0,0,1,1.987,1.675,7.632,7.632,0,0,1,.3,2.258q0,4.837,0,9.674v.3h5.864c0-.083.012-.147.012-.211,0-3.766.006-7.533,0-11.3a9.119,9.119,0,0,0-1.294-4.733,4.373,4.373,0,0,0-2.5-1.96,8.329,8.329,0,0,0-3.774-.294,6.4,6.4,0,0,0-4.918,3.379c-.02.037-.047.07-.072.108-.019-.021-.027-.026-.03-.033-.03-.068-.058-.136-.087-.2a5.1,5.1,0,0,0-3.41-3.061,8.118,8.118,0,0,0-3.346-.189,5.8,5.8,0,0,0-4.435,2.964c-.026.045-.056.089-.114.181-.006-.135-.013-.214-.013-.293,0-.723-.008-1.728,0-2.45Z" transform="translate(787.638 867.899)" fill="#111"/>
            <path id="Path_84" dataname="Path 84" d="M76.089,19.981a6.444,6.444,0,0,0-3.373-2.352,8.938,8.938,0,0,0-3.778-.278,5.987,5.987,0,0,0-4.826,3.416c-.02.04-.044.078-.066.117L64,20.875v-3.1H58.146V42.442H64V32.781l.051-.013.087.168a5.885,5.885,0,0,0,3.45,3.013,8.514,8.514,0,0,0,5.021.066A6.3,6.3,0,0,0,76,33.772a9.416,9.416,0,0,0,1.455-2.556,12.791,12.791,0,0,0,.692-5.786,10.5,10.5,0,0,0-2.055-5.45m-4.2,9.177a3.571,3.571,0,0,1-3.171,2.595,3.871,3.871,0,0,1-4.191-2.225,5.408,5.408,0,0,1-.45-1.6,7.536,7.536,0,0,1,.159-3.051,3.992,3.992,0,0,1,4.438-2.984,3.726,3.726,0,0,1,3.4,3.237c.123.548.158,1.117.232,1.676a8.3,8.3,0,0,1-.416,2.351" transform="translate(813.811 867.896)" fill="#111"/>
            <path id="Path_85" dataname="Path 85" d="M24.123,7.214H0v5.093H8.928V32.875h6.161V12.3h9.034Z" transform="translate(831 870.867)" fill="#111"/>
            <path id="Path_86" dataname="Path 86" d="M106.032,20.148a7.354,7.354,0,0,0-3.609-2.41,11.4,11.4,0,0,0-4.581-.4,9.144,9.144,0,0,0-6.759,3.982,9.935,9.935,0,0,0-1.471,8.2,8.31,8.31,0,0,0,6.056,6.326,11.649,11.649,0,0,0,5.774.3,7.8,7.8,0,0,0,6.439-5.494c.077-.236.131-.481.2-.726-.061-.015-.085-.024-.108-.026-1.488-.144-2.975-.284-4.462-.434-.158-.016-.192.063-.24.174a3.416,3.416,0,0,1-2.393,2.084,5.772,5.772,0,0,1-2.466.089,3.828,3.828,0,0,1-3.2-3.1c-.035-.189-.051-.382-.08-.6H108.2c0-.622,0-1.2,0-1.786a9.464,9.464,0,0,0-2.169-6.182M95.253,24.963a3.652,3.652,0,0,1,3.771-3.42,3.427,3.427,0,0,1,3.486,3.42Z" transform="translate(804.597 867.895)" fill="#111"/>
            <path id="Path_87" dataname="Path 87" d="M40.815,17.951c-1.412,3.6-2.949,7.469-4.366,11.067a.987.987,0,0,1-.063.159c-.071.115-.152.12-.22,0a1.372,1.372,0,0,1-.072-.183c-1.393-3.6-2.868-7.439-4.253-11.041,0,0-6.1.014-6.2.02.044.12.077.219.116.315q3.6,8.857,7.208,17.712a.55.55,0,0,1,0,.463c.233-.609-.746,1.849-.746,1.849a3.054,3.054,0,0,1-2.843,1.943l-2.814,0v5.049h3.716a7.129,7.129,0,0,0,6.577-4.377l9.746-22.972Z" transform="translate(823.421 867.694)" fill="#111"/>
            <path id="Path_88" dataname="Path 88" d="M132.742,25.685a10.48,10.48,0,0,0-1.285-.557,25.863,25.863,0,0,0-3.992-.839c-.653-.124-1.312-.22-1.962-.35a1.291,1.291,0,0,1-1.139-1.614,1.473,1.473,0,0,1,.954-.989,4.477,4.477,0,0,1,2.283-.17,4.662,4.662,0,0,1,1.14.287,2.712,2.712,0,0,1,1.747,2.135l4.853-.163c-.025-.2-.039-.372-.07-.539a6.179,6.179,0,0,0-4.126-4.894,12.73,12.73,0,0,0-6.382-.57,7.655,7.655,0,0,0-3.3,1.2,5.718,5.718,0,0,0-2.086,2.279,5.856,5.856,0,0,0-.313,4.08,3.9,3.9,0,0,0,2.05,2.606,13.3,13.3,0,0,0,2.082.871,36.345,36.345,0,0,0,4.735.9,4.424,4.424,0,0,1,1.454.431,1.323,1.323,0,0,1,.073,2.28,2.524,2.524,0,0,1-.506.247,5.185,5.185,0,0,1-3.522-.118,2.738,2.738,0,0,1-1.818-2.21c-.027-.166-.119-.161-.243-.157q-1.81.043-3.621.08c-.426.008-.852,0-1.309,0a5.893,5.893,0,0,0,3.143,5.115,10.963,10.963,0,0,0,4.318,1.25,13.715,13.715,0,0,0,5.148-.418,7.776,7.776,0,0,0,2.279-1.082,5.127,5.127,0,0,0,2.193-4.963,4.853,4.853,0,0,0-2.778-4.128" transform="translate(795.988 867.897)" fill="#111"/>
            <rect id="Rectangle_32" dataname="Rectangle 32" width="5.843" height="18.078" transform="translate(967.827 885.668)" fill="#111"/>
            <path id="Path_89" dataname="Path 89" d="M213.684,10.843h-5.8v5.03h-2.515v4.1h2.515V29.4a4.546,4.546,0,0,0,4.545,4.546h6.057V29.378l-3.95,0a.852.852,0,0,1-.852-.852V19.977h4.8v-4.1h-4.807Z" transform="translate(770.288 869.795)" fill="#111"/>
            <path id="Path_90" dataname="Path 90" d="M197.151,6.479a3.074,3.074,0,1,0,3.074,3.075,3.074,3.074,0,0,0-3.074-3.075" transform="translate(773.628 871.085)" fill="#111"/>
            <path id="Path_91" dataname="Path 91" d="M245.326,16.97c-.054-.094-.1-.19-.161-.283a4.549,4.549,0,0,0-2.744-2.062,8.806,8.806,0,0,0-3.661-.239,6.2,6.2,0,0,0-3.99,2.081c-.269.311-.489.665-.759,1.037V7.224h-5.856V32.871h5.863v-.313q0-4.468,0-8.937a6,6,0,0,1,.739-3.12,3.347,3.347,0,0,1,4.263-1.266,2.438,2.438,0,0,1,1.3,1.73,8.834,8.834,0,0,1,.251,1.968c.025,3.216.011,6.433.011,9.65v.3h4.741Z" transform="translate(763.554 870.865)" fill="#111"/>
            <rect id="Rectangle_33" dataname="Rectangle 33" width="1.756" height="40" transform="translate(1011.928 873)" fill="#111"/>
          </g>
        </svg>
      </h1>
      <ul className="items-center justify-between hidden lg:flex">
        <li className="mx-6"><a href="#overview" className="text-xl font-medium" onClick={(e) => scrollToDiv(e)}>Overview</a></li>
        <li className="mx-6"><a href="#howitworks" className="text-xl font-medium" onClick={(e) => scrollToDiv(e)}>How it works</a></li>
        <li className="mx-6"><a href="#pricing" className="text-xl font-medium" onClick={(e) => scrollToDiv(e)}>Pricing</a></li>
      </ul>
      <a href="https://calendly.com/connor-phelps-l0i/typesmith-demo?month=2024-02" className="text-white bg-offblack py-3 px-10 lg:px-16 rounded-full text-base lg:text-lg leading-normal font-medium transition-all ease-in-out translate-y-0 hover:-translate-y-px shadow-none hover:shadow-lg">Book a demo</a>
    </header>
  )
}

export default Header;