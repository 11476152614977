const Link = (props) => {
  var arrowDirection = props.leftArrow ? 'rotate-180' : ''; 
  return (
    <a href={props.url} className={`flex items-center group ${props.classes}`} aria-label={props.text}>
      <span className="transition-all ease-in-out block bg-offblack text-white py-4 pl-6 group-hover:pl-7 pr-6 rounded-full mr-8 lg:mr-12 shadow-none group-hover:shadow-lg">
        <svg xmlns="http://www.w3.org/2000/svg" width="22.037" height="17.178" viewBox="0 0 22.037 17.178" className={arrowDirection}>
          <g id="Group_75" data-name="Group 75" transform="translate(0 0.707)">
            <path id="Path_92" data-name="Path 92" d="M14768.658,3373.555l7.881,7.882-7.881,7.882" transform="translate(-14755.916 -3373.555)" fill="none" stroke="#fff" strokeWidth="2"/>
            <line id="Line_35" data-name="Line 35" x1="20.625" transform="translate(0 7.882)" fill="none" stroke="#fff" strokeWidth="2"/>
          </g>
        </svg>
      </span>
      <span className="font-medium text-base lg:text-[24px] leading-normal">{props.text}</span>
    </a>
  )
}

export default Link