import Link from "../Link/Link";

const Pricing = () => {
  const pricing_model = [
    {
      title: "Basic",
      price: "£100",
      description: "Up to 500 product descriptions",
      features: [
        "Product descriptions",
        "Meta title & meta descriptions"
      ],
      note: "You provide us with your product title and details or product imagery, and we’ll do the rest."
    },
    {
      title: "Plus",
      price: "£500",
      description: "Up to 5000 product descriptions",
      features: [
        "Product descriptions",
        "Meta title & meta descriptions",
        "Google Shopping Ad titles & descriptions",
        "Image alt text optimisations",
        "Product focussed social media post ideas"
      ],
      plus: [
        "Access to our SEO experts to fine-tune your content’s tone of voice and target audience",
        "2 rounds of amends"
      ]
    },
    {
      title: "Premium",
      price: "£1200",
      description: "5000+ product descriptions",
      features: [
        "Product descriptions",
        "Meta title & meta descriptions",
        "Google Shopping Ad titles & descriptions",
        "Image alt text optimisations",
        "Product focussed social media post ideas",
        "Collection page copy"
      ],
      plus: [
        "Access to our SEO experts to fine-tune your content’s tone of voice and target audience",
        "2 rounds of amends",
        "Keyword gap analysis - discover keywords your competitors are ranking for which you aren’t",
        "Collection page optimisations for newly created pages"
      ]
    }
  ]

  return (
    <div id="pricing">
      {/* <div className="section-grid page-width">
        <div className="p-6 lg:p-12 lg:col-start-2 lg:col-span-5 flex items-center">
          <div>
            <h2 className="text-xl lg:text-3xl text-[#858585] mb-4 font-medium">Pricing</h2>
            <h3 className="font-medium leading-none	text-2xl lg:text-[48px] mb-8">Optimised product descriptions, fine-tuned for every part of the web.</h3>
            <Link url="https://calendly.com/connor-phelps-l0i/typesmith-demo?month=2024-02" text="Book a Demo" classes="mt-8" />
          </div>
        </div>
        <div className="lg:col-start-7 lg:col-span-5 px-6 lg:px-0">
          <img src="../../assets/goal.svg" alt="Pricing Graphic" loading="lazy" className="z-2 relative" />
          <div className="bg-lightgrey p-16 rounded-xl hidden lg:block relative -mt-12 z-1"></div>
        </div>
      </div> */}
      <div className="section-grid page-width mt-12 lg:mt-20 px-6">
        <div className="lg:col-start-2 lg:col-span-11">
        <h2 className="text-xl lg:text-3xl text-[#858585] mb-4 font-medium">Pricing</h2>
        </div>
        <div className="lg:col-start-2 lg:col-span-10">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {pricing_model.map((model, index) => (
              <div key={index} className="flex">
                <div className="bg-white rounded-md shadow-md">
                  <div className="bg-lightgrey p-6 lg:px-12 lg:py-8 border-t-[10px] border-yellow rounded-t-sm">
                    <h3 className="text-base lg:text-lg font-medium mb-2 uppercase text-black">Typesmith {model.title}</h3>
                    <p className="text-base lg:text-xl mb-8 text-offblack">{model.description}</p>
                    <h4 className="text-2xl lg:text-3xl font-bold">{model.price}</h4>
                  </div>
                  <div className="p-6 lg:p-12">
                    <p>What you get</p>
                    <p className="mb-8">Perfectly Optimised:</p>
                    <ul>
                      {model.features.map((feature, index) => (
                        <li key={index} className="mt-6">{feature}</li>
                      ))}
                    </ul>
                    {model.note && (
                      <p className="mb-4">{model.note}</p>
                    )}
                  </div>
                  {model.plus && (
                    <div className="p-6 lg:p-12 border-t border-[#E1E3E5]">
                      <p className="mb-8">Plus</p>
                      <ul>
                        {model.plus.map((feature, index) => (
                          <li key={index} className="mt-6">{feature}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="flex items-center justify-center mt-12">
            <a href="https://chat.openai.com/g/g-jcRMVzX6E-typesmith-seo-optimised-ecommerce-copy" className="text-white bg-offblack py-3 px-10 lg:px-16 rounded-full text-base lg:text-lg leading-normal font-medium transition-all ease-in-out translate-y-0 hover:-translate-y-px shadow-none hover:shadow-lg">Try it out</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pricing