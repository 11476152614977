import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import Overview from '../components/Overview/Overview';
import SplitSection from '../components/SplitSection/SplitSection';
import HowItWorks from '../components/HowItWorks/HowItWorks';
import Footer from '../components/Footer/Footer';
import Pricing from '../components/Pricing/Pricing';
import Copy from '../components/Copy/Copy';
import FAQ from '../components/FAQ/FAQ';

const Home = () => {
  return (
    <div className="App page-width">
      <Header />
      <Hero />
      <Overview />
      <SplitSection />
      <HowItWorks />
      <Copy />
      <Pricing />
      <FAQ />
      <Footer />
    </div>
  )
}

export default Home