const Footer = () => {
  return (
    <footer className="section-grid px-6 lg:px-0">
      <div className="lg:col-start-2 lg:col-span-11 lg:col-end-12">
        <div className="flex items-center justify-between py-6 lg:py-12 border-b border-[#EFEFEF]">
          <svg xmlns="http://www.w3.org/2000/svg" width="182.683" height="40" viewBox="0 0 182.683 40" className="w-36 lg:w-auto">
            <g id="Group_74" dataname="Group 74" transform="translate(-831 -873)">
              <path id="Path_83" dataname="Path 83" d="M146.683,17.753V35.837h5.861V35.5q0-4.444,0-8.886a6.786,6.786,0,0,1,.6-3.011,2.787,2.787,0,0,1,2.737-1.685,2.272,2.272,0,0,1,2.548,1.988,9.329,9.329,0,0,1,.228,2.022c.023,3.208.01,6.416.01,9.625v.285h5.918c0-.125,0-.231,0-.337,0-3.11-.008-6.22.013-9.33a5.742,5.742,0,0,1,.747-2.9,2.565,2.565,0,0,1,1.261-1.127,3.63,3.63,0,0,1,1.767-.2,2.167,2.167,0,0,1,1.987,1.675,7.632,7.632,0,0,1,.3,2.258q0,4.837,0,9.674v.3h5.864c0-.083.012-.147.012-.211,0-3.766.006-7.533,0-11.3a9.119,9.119,0,0,0-1.294-4.733,4.373,4.373,0,0,0-2.5-1.96,8.329,8.329,0,0,0-3.774-.294,6.4,6.4,0,0,0-4.918,3.379c-.02.037-.047.07-.072.108-.019-.021-.027-.026-.03-.033-.03-.068-.058-.136-.087-.2a5.1,5.1,0,0,0-3.41-3.061,8.118,8.118,0,0,0-3.346-.189,5.8,5.8,0,0,0-4.435,2.964c-.026.045-.056.089-.114.181-.006-.135-.013-.214-.013-.293,0-.723-.008-1.728,0-2.45Z" transform="translate(787.638 867.899)" fill="#111"/>
              <path id="Path_84" dataname="Path 84" d="M76.089,19.981a6.444,6.444,0,0,0-3.373-2.352,8.938,8.938,0,0,0-3.778-.278,5.987,5.987,0,0,0-4.826,3.416c-.02.04-.044.078-.066.117L64,20.875v-3.1H58.146V42.442H64V32.781l.051-.013.087.168a5.885,5.885,0,0,0,3.45,3.013,8.514,8.514,0,0,0,5.021.066A6.3,6.3,0,0,0,76,33.772a9.416,9.416,0,0,0,1.455-2.556,12.791,12.791,0,0,0,.692-5.786,10.5,10.5,0,0,0-2.055-5.45m-4.2,9.177a3.571,3.571,0,0,1-3.171,2.595,3.871,3.871,0,0,1-4.191-2.225,5.408,5.408,0,0,1-.45-1.6,7.536,7.536,0,0,1,.159-3.051,3.992,3.992,0,0,1,4.438-2.984,3.726,3.726,0,0,1,3.4,3.237c.123.548.158,1.117.232,1.676a8.3,8.3,0,0,1-.416,2.351" transform="translate(813.811 867.896)" fill="#111"/>
              <path id="Path_85" dataname="Path 85" d="M24.123,7.214H0v5.093H8.928V32.875h6.161V12.3h9.034Z" transform="translate(831 870.867)" fill="#111"/>
              <path id="Path_86" dataname="Path 86" d="M106.032,20.148a7.354,7.354,0,0,0-3.609-2.41,11.4,11.4,0,0,0-4.581-.4,9.144,9.144,0,0,0-6.759,3.982,9.935,9.935,0,0,0-1.471,8.2,8.31,8.31,0,0,0,6.056,6.326,11.649,11.649,0,0,0,5.774.3,7.8,7.8,0,0,0,6.439-5.494c.077-.236.131-.481.2-.726-.061-.015-.085-.024-.108-.026-1.488-.144-2.975-.284-4.462-.434-.158-.016-.192.063-.24.174a3.416,3.416,0,0,1-2.393,2.084,5.772,5.772,0,0,1-2.466.089,3.828,3.828,0,0,1-3.2-3.1c-.035-.189-.051-.382-.08-.6H108.2c0-.622,0-1.2,0-1.786a9.464,9.464,0,0,0-2.169-6.182M95.253,24.963a3.652,3.652,0,0,1,3.771-3.42,3.427,3.427,0,0,1,3.486,3.42Z" transform="translate(804.597 867.895)" fill="#111"/>
              <path id="Path_87" dataname="Path 87" d="M40.815,17.951c-1.412,3.6-2.949,7.469-4.366,11.067a.987.987,0,0,1-.063.159c-.071.115-.152.12-.22,0a1.372,1.372,0,0,1-.072-.183c-1.393-3.6-2.868-7.439-4.253-11.041,0,0-6.1.014-6.2.02.044.12.077.219.116.315q3.6,8.857,7.208,17.712a.55.55,0,0,1,0,.463c.233-.609-.746,1.849-.746,1.849a3.054,3.054,0,0,1-2.843,1.943l-2.814,0v5.049h3.716a7.129,7.129,0,0,0,6.577-4.377l9.746-22.972Z" transform="translate(823.421 867.694)" fill="#111"/>
              <path id="Path_88" dataname="Path 88" d="M132.742,25.685a10.48,10.48,0,0,0-1.285-.557,25.863,25.863,0,0,0-3.992-.839c-.653-.124-1.312-.22-1.962-.35a1.291,1.291,0,0,1-1.139-1.614,1.473,1.473,0,0,1,.954-.989,4.477,4.477,0,0,1,2.283-.17,4.662,4.662,0,0,1,1.14.287,2.712,2.712,0,0,1,1.747,2.135l4.853-.163c-.025-.2-.039-.372-.07-.539a6.179,6.179,0,0,0-4.126-4.894,12.73,12.73,0,0,0-6.382-.57,7.655,7.655,0,0,0-3.3,1.2,5.718,5.718,0,0,0-2.086,2.279,5.856,5.856,0,0,0-.313,4.08,3.9,3.9,0,0,0,2.05,2.606,13.3,13.3,0,0,0,2.082.871,36.345,36.345,0,0,0,4.735.9,4.424,4.424,0,0,1,1.454.431,1.323,1.323,0,0,1,.073,2.28,2.524,2.524,0,0,1-.506.247,5.185,5.185,0,0,1-3.522-.118,2.738,2.738,0,0,1-1.818-2.21c-.027-.166-.119-.161-.243-.157q-1.81.043-3.621.08c-.426.008-.852,0-1.309,0a5.893,5.893,0,0,0,3.143,5.115,10.963,10.963,0,0,0,4.318,1.25,13.715,13.715,0,0,0,5.148-.418,7.776,7.776,0,0,0,2.279-1.082,5.127,5.127,0,0,0,2.193-4.963,4.853,4.853,0,0,0-2.778-4.128" transform="translate(795.988 867.897)" fill="#111"/>
              <rect id="Rectangle_32" dataname="Rectangle 32" width="5.843" height="18.078" transform="translate(967.827 885.668)" fill="#111"/>
              <path id="Path_89" dataname="Path 89" d="M213.684,10.843h-5.8v5.03h-2.515v4.1h2.515V29.4a4.546,4.546,0,0,0,4.545,4.546h6.057V29.378l-3.95,0a.852.852,0,0,1-.852-.852V19.977h4.8v-4.1h-4.807Z" transform="translate(770.288 869.795)" fill="#111"/>
              <path id="Path_90" dataname="Path 90" d="M197.151,6.479a3.074,3.074,0,1,0,3.074,3.075,3.074,3.074,0,0,0-3.074-3.075" transform="translate(773.628 871.085)" fill="#111"/>
              <path id="Path_91" dataname="Path 91" d="M245.326,16.97c-.054-.094-.1-.19-.161-.283a4.549,4.549,0,0,0-2.744-2.062,8.806,8.806,0,0,0-3.661-.239,6.2,6.2,0,0,0-3.99,2.081c-.269.311-.489.665-.759,1.037V7.224h-5.856V32.871h5.863v-.313q0-4.468,0-8.937a6,6,0,0,1,.739-3.12,3.347,3.347,0,0,1,4.263-1.266,2.438,2.438,0,0,1,1.3,1.73,8.834,8.834,0,0,1,.251,1.968c.025,3.216.011,6.433.011,9.65v.3h4.741Z" transform="translate(763.554 870.865)" fill="#111"/>
              <rect id="Rectangle_33" dataname="Rectangle 33" width="1.756" height="40" transform="translate(1011.928 873)" fill="#111"/>
            </g>
          </svg>
          <a href="https://venturestream.co.uk" target="_blank" rel="noreferrer" className="text-4xl font-bold" aria-label="Venture Stream">
            <svg xmlns="http://www.w3.org/2000/svg" xmlnslink="http://www.w3.org/1999/xlink" width="274.528" height="40" viewBox="0 0 274.528 40" className="w-36 lg:w-auto">
              <defs>
                <clipPath id="clipPath">
                  <path id="Path_106" dataname="Path 106" d="M112.39,25a10,10,0,0,0,0,20h4.056l9.712-20Z" transform="translate(-102.39 -25)" fill="none"/>
                </clipPath>
                <clipPath id="clipPath-2">
                  <path id="Path_107" dataname="Path 107" d="M63.26,94.334h20l-10-20.444Z" transform="translate(-63.26 -73.89)" fill="none"/>
                </clipPath>
                <clipPath id="clipPath-3">
                  <path id="Path_108" dataname="Path 108" d="M122.968,75,113.26,95h13.768a10,10,0,0,0,0-20Z" transform="translate(-113.26 -75)" fill="none"/>
                </clipPath>
              </defs>
              <g id="Group_90" dataname="Group 90" transform="translate(-1672.135 -4225.387)">
                <path id="Path_93" dataname="Path 93" d="M185.674,63.636,177.93,40.56h4.944L188.51,58.3l5.6-17.736h4.98l-7.78,23.076Z" transform="translate(1565.377 4201.051)"/>
                <path id="Path_94" dataname="Path 94" d="M240.122,91.316q.856,3.1,4.648,3.1a4.348,4.348,0,0,0,3.692-1.648l3.428,1.976a8.183,8.183,0,0,1-7.2,3.528,8.872,8.872,0,0,1-6.564-2.472,8.7,8.7,0,0,1,6.232-14.936,7.852,7.852,0,0,1,6,2.508,8.7,8.7,0,0,1,2.356,6.2,9.747,9.747,0,0,1-.164,1.748Zm-.064-3.3h8.4a3.964,3.964,0,0,0-1.5-2.5,4.276,4.276,0,0,0-2.588-.824,4.488,4.488,0,0,0-2.836.872,4.18,4.18,0,0,0-1.48,2.456" transform="translate(1525.009 4166.872)"/>
                <path id="Path_95" dataname="Path 95" d="M289.148,80.856a5.972,5.972,0,0,1,4.452,1.816,6.881,6.881,0,0,1,1.764,5.012V97.8h-4.236V88.2a3.372,3.372,0,0,0-.892-2.5,3.232,3.232,0,0,0-2.372-.872,3.512,3.512,0,0,0-2.64,1.02,4.266,4.266,0,0,0-.988,3.068v8.9H280V81.3h4.252v1.848a5.509,5.509,0,0,1,4.912-2.312" transform="translate(1498.399 4166.883)"/>
                <path id="Path_96" dataname="Path 96" d="M329.048,79.2H325.32V86.06a1.6,1.6,0,0,0,.428,1.252A1.96,1.96,0,0,0,327,87.76a19.738,19.738,0,0,0,2.044,0v3.856q-4.4.492-6.18-.824t-1.8-4.716V79.2H318.2V75.1h2.868V71.788L325.32,70.5v4.6h3.724Z" transform="translate(1475.48 4173.087)"/>
                <path id="Path_97" dataname="Path 97" d="M358.194,82h4.252V98.484h-4.252V96.636a5.488,5.488,0,0,1-4.912,2.308,5.968,5.968,0,0,1-4.468-1.812,6.881,6.881,0,0,1-1.764-5.012V82H351.3v9.6a3.372,3.372,0,0,0,.892,2.524,3.248,3.248,0,0,0,2.372.872,3.5,3.5,0,0,0,2.636-1.02,4.255,4.255,0,0,0,.992-3.068Z" transform="translate(1458.169 4166.188)"/>
                <path id="Path_98" dataname="Path 98" d="M379.076,84.366a4.428,4.428,0,0,1,1.96-2.376,5.968,5.968,0,0,1,3.048-.8v4.756a5.016,5.016,0,0,0-3.476.8,3.774,3.774,0,0,0-1.532,3.4v7.88H374.82V81.514h4.256Z" transform="translate(1441.507 4166.674)"/>
                <path id="Path_99" dataname="Path 99" d="M401.5,91.316q.86,3.1,4.648,3.1a4.359,4.359,0,0,0,3.7-1.648l3.428,1.976a8.2,8.2,0,0,1-7.2,3.528,8.876,8.876,0,0,1-6.56-2.472,8.7,8.7,0,0,1,6.228-14.936,7.852,7.852,0,0,1,6,2.508,8.7,8.7,0,0,1,2.36,6.2,9.766,9.766,0,0,1-.168,1.748Zm-.08-3.292h8.4a4,4,0,0,0-1.5-2.5,4.276,4.276,0,0,0-2.588-.824,4.476,4.476,0,0,0-2.832.872,4.164,4.164,0,0,0-1.476,2.456" transform="translate(1428.175 4166.872)"/>
                <path id="Path_100" dataname="Path 100" d="M469.4,88.4a10.316,10.316,0,0,1-5.772-1.532,8.2,8.2,0,0,1-3.3-4.172l3.892-2.272a5.184,5.184,0,0,0,5.308,3.528,4.574,4.574,0,0,0,2.8-.692,2.18,2.18,0,0,0-.2-3.644,16.894,16.894,0,0,0-3.892-1.468,24.036,24.036,0,0,1-2.62-.924,11.355,11.355,0,0,1-2.144-1.236,5.068,5.068,0,0,1-1.632-1.96,6.452,6.452,0,0,1-.56-2.768,6.176,6.176,0,0,1,2.224-5,8.092,8.092,0,0,1,5.36-1.864,8.912,8.912,0,0,1,4.928,1.368,9.223,9.223,0,0,1,3.312,3.808l-3.824,2.208a4.594,4.594,0,0,0-4.4-2.964,3.492,3.492,0,0,0-2.228.64,2.04,2.04,0,0,0-.8,1.668,2.108,2.108,0,0,0,.908,1.764,12.74,12.74,0,0,0,3.48,1.464c.7.22,1.236.4,1.6.512s.86.308,1.484.56a8.806,8.806,0,0,1,1.452.708,11.9,11.9,0,0,1,1.124.9,4.548,4.548,0,0,1,1.008,1.152,6.607,6.607,0,0,1,.592,1.436,6.473,6.473,0,0,1,.248,1.828,6.2,6.2,0,0,1-2.324,5.08,9.3,9.3,0,0,1-6.048,1.876" transform="translate(1390.202 4176.751)"/>
                <path id="Path_101" dataname="Path 101" d="M509.856,79.2h-3.724V86.06a1.6,1.6,0,0,0,.428,1.252,1.96,1.96,0,0,0,1.252.448,19.74,19.74,0,0,0,2.044,0v3.856q-4.4.492-6.18-.824t-1.8-4.732V79.2H499V75.1h2.868V71.788L506.12,70.5v4.6h3.724Z" transform="translate(1366.999 4173.087)"/>
                <path id="Path_102" dataname="Path 102" d="M531.962,84.365a4.444,4.444,0,0,1,1.96-2.376,5.972,5.972,0,0,1,3.052-.8v4.756a5.028,5.028,0,0,0-3.48.8,3.783,3.783,0,0,0-1.532,3.4v7.88H527.71V81.513h4.252Z" transform="translate(1349.773 4166.674)"/>
                <path id="Path_103" dataname="Path 103" d="M554.429,91.322q.852,3.1,4.648,3.1a4.355,4.355,0,0,0,3.692-1.648L566.2,94.75a8.19,8.19,0,0,1-7.2,3.528,8.875,8.875,0,0,1-6.56-2.472,8.712,8.712,0,0,1,6.26-14.932,7.86,7.86,0,0,1,6,2.508,8.716,8.716,0,0,1,2.356,6.2,10.116,10.116,0,0,1-.164,1.748Zm-.068-3.3h8.4a4,4,0,0,0-1.5-2.5,4.284,4.284,0,0,0-2.588-.824,4.488,4.488,0,0,0-2.836.872,4.184,4.184,0,0,0-1.484,2.456" transform="translate(1336.406 4166.865)"/>
                <path id="Path_104" dataname="Path 104" d="M607.1,81.3h4.256V97.782H607.1V95.838a6.47,6.47,0,0,1-5.372,2.4,7.464,7.464,0,0,1-5.652-2.52,9.277,9.277,0,0,1,0-12.364,7.468,7.468,0,0,1,5.652-2.524,6.477,6.477,0,0,1,5.372,2.4Zm-7.844,11.6a4.4,4.4,0,0,0,3.264,1.3,4.448,4.448,0,0,0,3.28-1.3,4.956,4.956,0,0,0,0-6.692,4.448,4.448,0,0,0-3.28-1.3,4.4,4.4,0,0,0-3.264,1.3,5,5,0,0,0,0,6.692" transform="translate(1310.172 4166.89)"/>
                <path id="Path_105" dataname="Path 105" d="M657.732,80.856a5.86,5.86,0,0,1,4.532,1.848,7.016,7.016,0,0,1,1.7,4.944V97.8H659.7V87.948a3.368,3.368,0,0,0-.724-2.308,2.568,2.568,0,0,0-2.044-.824,2.776,2.776,0,0,0-2.256.956,4.21,4.21,0,0,0-.8,2.768V97.8h-4.252V87.948a3.368,3.368,0,0,0-.724-2.308,2.568,2.568,0,0,0-2.044-.824,2.844,2.844,0,0,0-2.256.956,4.07,4.07,0,0,0-.84,2.768V97.8H639.5V81.3h4.256v1.748a5.142,5.142,0,0,1,4.58-2.212,4.881,4.881,0,0,1,4.484,2.376,5.592,5.592,0,0,1,4.912-2.376" transform="translate(1282.699 4166.883)"/>
                <g id="Group_87" dataname="Group 87" transform="translate(1707.356 4225.387)" clipPath="url(#clipPath)">
                  <rect id="Rectangle_43" dataname="Rectangle 43" width="23.768" height="20" transform="translate(-0.004)"/>
                </g>
                <g id="Group_88" dataname="Group 88" transform="translate(1691.703 4244.943)" clipPath="url(#clipPath-2)">
                  <rect id="Rectangle_44" dataname="Rectangle 44" width="20" height="20.444" transform="translate(-0.004)"/>
                </g>
                <g id="Group_89" dataname="Group 89" transform="translate(1711.703 4245.387)" clipPath="url(#clipPath-3)">
                  <rect id="Rectangle_45" dataname="Rectangle 45" width="23.768" height="20" transform="translate(-0.004)"/>
                </g>
                <path id="Path_109" dataname="Path 109" d="M20,0H0L19.564,40l10-20.444Z" transform="translate(1672.135 4225.387)"/>
              </g>
            </svg>
          </a>
        </div>
        <div className="flex items-left lg:items-start justify-between flex-col lg:flex-row py-6 lg:py-12 ">
          <div>
            <p className="text-[#858585] text-xs lg:text-base leading-normal font-normal">&copy; 2024 Venture Stream</p>
            <p className="text-[#858585] text-xs lg:text-base leading-normal font-normal">Company number 08054842</p>
            <p className="text-[#858585] text-xs lg:text-base leading-normal font-normal my-4">Venture Stream Ltd<br/>
              63 Westgate Rd<br/>
              Newcastle upon Tyne<br/>
              NE1 1SG
            </p>
            <a href="mailto:hi@venturestream.co.uk" className="text-[#858585] text-xs lg:text-base leading-normal font-normal block">hi@venturestream.co.uk</a>
            <a href="tel:01918090704" className="text-[#858585] text-xs lg:text-base leading-normal font-normal block">0191 809 0704</a>
          </div>
          <ul className="list-style-none mt-8 md:mt-0">
            <li><a className="text-[#858585] text-xs lg:text-base leading-normal font-normal" href="/terms-and-conditions">Terms &amp; Conditions</a></li>
            <li><a className="text-[#858585] text-xs lg:text-base leading-normal font-normal" href="/privacy-policy">Privacy Policy</a></li>
            <li><a className="text-[#858585] text-xs lg:text-base leading-normal font-normal" href="/cookie-policy">Cookie Policy</a></li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer