const CardGraphic = () => {
  return (
    <div className="relative block mt-4 lg:-mt-20 ml-8 lg:ml-20 lg:mb-10">
      <div className="relative card z-3 h-auto">
        <div className="bg-[#EDEDED] rounded-xl w-full h-[200px] lg:h-[357px] mb-8">
          <img src="../../assets/card-graphic.svg" alt="Card Graphic" loading="lazy" className="w-auto h-auto lg:h-[400px] relative -top-4 lg:-top-12 -right-4 lg:-right-8" width="331.214" height="352.9" />
        </div>
        <strong className="text-base lg:text-xl leading-normal mb-4">Men’s Star Graphic Tee</strong>
        <span className="text-base lg:text-xl leading-normal block">&pound;19.99</span>
      </div>
      <div className="card z-2 h-[304px] lg:h-[543px]">
        <div className="bg-[#EDEDED] rounded-xl w-full h-[200px] lg:h-[357px] mb-8"></div>
      </div>
      <div className="card z-1 h-[304px] lg:h-[543px]">
        <div className="bg-[#EDEDED] rounded-xl w-full h-[200px] lg:h-[357px] mb-8"></div>
      </div>
    </div>
  )
}

export default CardGraphic