import Link from "../Link/Link"

const Copy = () => {
  return (
    <div id="transform" className="section-grid mb-12 md:mb-10">
      <div className="p-6 lg:p-12 lg:col-start-7 lg:col-span-5 flex items-center order-1 lg:order-2">
        <div>
          <h3 className="font-medium text-2xl lg:text-[38px] mb-8">Transform images into product<br/>descriptions</h3>
          <p className="text-base lg:text-xl leading-normal mt-4 mb-8">Our AI can even analyse images, writing a product description based on a photograph alone. </p>
          <p className="text-base lg:text-xl leading-normal mt-4 mb-8">For the best results, however, we’d recommend providing a clear, high-resolution image combined with a basic product title and attribute information such as colour, size, and any features that cannot be determined from an image.</p>
          <Link url="https://calendly.com/connor-phelps-l0i/typesmith-demo?month=2024-02" text="Book a Demo" />
        </div>
      </div>
      <div className="lg:col-start-2 lg:col-span-5 px-6 lg:px-0 order-2 lg:order-1">
        <img src="../../assets/copy.svg" alt="Transform Graphic" loading="lazy" className="z-2 relative" />
      </div>
    </div>
  )
}

export default Copy