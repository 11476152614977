import Link from "../components/Link/Link"

const Terms = () => {
  return (
    <div className="App page-width px-6">
      <div className="mt-4 mb-12">
        <Link url="/" text="Back to home" leftArrow={true} />
      </div>
      <h1 className="font-bold text-3xl mb-8">Terms and Conditions</h1>
      <h2 className="font-bold text-2xl my-4">1. Introduction</h2>
      <p>Welcome to Typesmith.Ai ("Service"), operated by Venture Stream Ltd, a company registered in England and Wales with registration number 08054842 ("we", "us", "our"). These Terms and Conditions ("Terms") govern your access to and use of our services and any related software, platforms, and technologies provided by Typesmith.Ai.</p>
      <h2 className="font-bold text-2xl my-4">2. Acceptance of Terms</h2>
      <p>By accessing or using the Service, you confirm that you accept these Terms and that you agree to comply with them. If you do not agree to these Terms, you must not use our Service.</p>
      <h2 className="font-bold text-2xl my-4">3. Service Description</h2>
      <p>Typesmith.Ai offers AI-powered copywriting services designed to optimize e-commerce content, including but not limited to product descriptions, meta titles, meta descriptions, and advertising copy. Our Service utilizes advanced AI technology to generate content based on input data provided by you.</p>
      <h2 className="font-bold text-2xl my-4">4. User Obligations and Conduct</h2>
      <p>You agree to use Typesmith.Ai only for lawful purposes and in a manner that does not infringe the rights of, or restrict or inhibit the use and enjoyment of the Service by any third party.</p>
      <p>You are responsible for ensuring that any data, including product titles, attributes, or images you submit, is accurate and does not contain any material that is copyrighted, illegal, offensive, or otherwise objectionable.</p>
      <p>You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of the Service without express written permission from us.</p>
      <h2 className="font-bold text-2xl my-4">5. Intellectual Property Rights</h2>
      <p>The Service and all related content, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio) are owned by Venture Stream Ltd, its licensors, or other providers of such material and are protected by international copyright, patent, trademark, and other intellectual property or proprietary rights laws.</p>
      <h2 className="font-bold text-2xl my-4">6. Disclaimer of Warranties and Limitation of Liability</h2>
      <p>The Service is provided on an "as is" and "as available" basis without any warranties of any kind, either express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>
      <p>In no event will Venture Stream Ltd, its directors, employees, partners, agents, suppliers, or affiliates be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any content obtained from the Service; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage.</p>
      <h2 className="font-bold text-2xl my-4">7. Modifications to the Terms and the Service</h2>
      <p>We reserve the right to modify these Terms at any time. We will provide notice of any changes by posting the new Terms on the Service. Your continued use of the Service after any such change constitutes your acceptance of the new Terms.</p>
      <h2 className="font-bold text-2xl my-4">8. Governing Law and Jurisdiction</h2>
      <p>These Terms shall be governed and construed in accordance with the laws of England and Wales, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.</p>
      <h2 className="font-bold text-2xl my-4">9. Contact Us</h2>
      <p>If you have any questions about these Terms, please contact us at <a href="mailto:hi@venturestream.co.uk">hi@venturestream.co.uk</a>.</p>
    </div>
  )
}

export default Terms