import Question from "../Question/Question";

const FAQ = () => {

  const questions = [
    {
      question: "What is product description writing?",
      answer: "When we use the term product description writing, we’re referring to the act of creating the copy that appears on the product description pages of an ecommerce website. This piece of text is used to describe the product and explain the benefits to potential customers, and should contain all of the information and details needed for consumers to decide whether it suits their needs. While generally short in word count, product description writing can be time consuming and tedious if you have an extensive product inventory."
    },
    {
      question: "What is the expected delivery time?",
      answer: "Delivery times can vary based on the number of products in question, but we propose one to two days based on 1000 products. This time frame allows for discussions with your team, any copy amends that may be required and upload to your Shopify store."
    },
    {
      question: "Is Typesmith better than Chat GPT?",
      answer: "While Chat GPT can be used to speed up the process of writing product copy, it helps to have a professional copywriter and SEO/PPC expert to oversee the process. By opting for our managed service, we’ll ensure every single description makes sense, suits your brand’s tone of voice and is targeting the right audience. We’ve been working with open AI for over a year, and have developed a desktop system to deliver more accurate results."
    },
    {
      question: "Does Typesmith work for any type of product?",
      answer: "While Typesmith can be used for a wide range of everyday objects, it isn’t well suited to very niche products sold in niche markets (for example equipment used by sound engineers). It’s best used for products with larger audiences."
    }
  ]

  return (
    <div className="px-6 border-t border-lightgrey my-12 lg:my-10 pt-12 lg:pt-20">
      <div className="max-w-[888px] mx-auto">
        <h2 className="font-medium leading-none	text-2xl lg:text-[48px] mb-8 lg:mb-10">Common Questions</h2>
        <div className="grid gap-8">
          {questions.map((question, index) => {
            return (
              <Question key={index} question={question.question} answer={question.answer} />
            )
          })}
          </div>
      </div>
    </div>

  );
}

export default FAQ;