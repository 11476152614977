import { TerminalEmulator } from "../../helpers/terminal-emulator"
import { useEffect } from "react";
import CardGraphic from "../CardGraphic/CardGraphic";

const Overview = () => {
  let executed = false;

  const runEmulator = () => {
    if (!executed) {
      executed = true;
      var TE = TerminalEmulator.init(document.getElementById('screen'));
  
      TE.wait(1000, false)
        .then( TE.enterInput.bind(TE, 'Get product title') )
        .then( TE.enterCommand.bind( TE ) )
        .then( TE.enterInput.bind(TE, '“Men’s Star Graphic Tee”') )
        .then( TE.enterCommand.bind( TE ) )
        .then( TE.enterInput.bind(TE, 'Gathering data') )
        .then( TE.enterCommand.bind( TE ) )
        .then( TE.enterInput.bind(TE, 'Generating smart product description_') )
    };
  }

  useEffect(() => {
    const onScroll = () => {
      let overview = document.getElementById('overview');
      let rect = overview.getBoundingClientRect();

      if(rect.y < 750) {
        overview.classList.add('animate-section');
        runEmulator();
        window.removeEventListener('scroll', onScroll);
      }
    };
    window.addEventListener('scroll', onScroll);
    // eslint-disable-next-line
  }, []);
  
  return (
    <div id="overview" className="relative section-grid my-8 lg:my-10 px-6 lg:px-0">
      <div className="relative lg:col-start-2 lg:col-span-10 flex items-center lg:justify-end">
        <p className="lg:max-w-[40%] lg:text-lg">We’ve developed intuitive software that combines copywriting with the power of AI, saving you time and energy crafting product copy for your website or paid ads. And with the input of our SEO and PPC experts, you can rest assured that your copy is in capable hands.</p>
      </div>
      <div className="relative lg:col-start-2 lg:col-span-6">
        <div className=" bg-lightgrey rounded-2xl mt-16 lg:my-0 pb-0 lg:pb-8">
          <CardGraphic />
        </div>
        <img src="../../assets/cart.svg" alt="Cart Graphic" loading="lazy" className="hidden lg:block relative float-right -mt-20" />
      </div>
      <div className="lg:col-start-7 lg:col-span-5 bg-black rounded-2xl p-6 lg:p-16 relative lg:absolute lg:top-40 z-2">
        <h4 className="text-lg lg:text-xl font-medium text-yellow">Fast & Smart</h4>
        <div className="relative h-[150px] my-8">
          <div id="screen" className="terminal_emulator"></div>
        </div>
        <p className="text-[#BEBEBE] text-base lg:text-lg leading-normal">Typesmith can generate thousands of product descriptions within minutes, each with useful and relevant information and written in a friendly, human tone.</p>
      </div>
    </div>
  )
}

export default Overview