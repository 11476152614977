const Overview = () => {
  var sectionClasses = "border-t border-lightgrey mb-8 lg:mb-0";
  var titleClasses = "py-8 lg:py-16 text-[22px] md:text-[38px] font-medium";
  var paraClasses = "mb-4";
  
  return (
    <div id="splitSection" className="relative section-grid my-8 lg:my-10 px-6 lg:px-0">
      <div className={sectionClasses + " lg:col-start-2 lg:col-span-5"}>
        <h3 className={titleClasses}>SEO</h3>
        <div>
          <p className={paraClasses}>Product descriptions are often neglected, but well-crafted product copy can play a crucial role in improving a website’s search engine optimisation (SEO), attracting new customers and encouraging conversions.</p>
          <p className={paraClasses}>But when you have hundreds (or even thousands) of products in your catalogue, writing unique, high-quality descriptions for every single one is time consuming - even for experienced copywriters. Our team can save you serious time by combining our SEO expertise with the power of AI to produce high-quality, fully optimised product descriptions that are crafted to convert.</p>
        </div>
      </div>
      <div className={sectionClasses + " lg:col-start-7 lg:col-span-5"}>
        <h3 className={titleClasses}>PPC</h3>
        <p className={paraClasses}>Pay-per-click campaigns allow you to target specific demographics, getting your products in front of the right people at the right time. But only with the right strategy and execution.</p>
        <p className={paraClasses}>We can use Typesmith to identify high volume keywords relevant to your product offering, to create ad copy for search campaigns and to write titles and descriptions for your Google Shopping feed.</p>
        <p className={paraClasses}>It’s not enough for a potential customer to find your ad - they need to click on it to convert. And the copy and titles you choose can substantially impact click through rates. Our PPC consultants will harness AI software to help you get the most out of your advertising budget.</p>
      </div>
    </div>
  )
}

export default Overview