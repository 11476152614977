import { useState, useEffect } from "react" 

const Question = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if(props.index === 0) {
      setIsOpen(true);
    }
  }, [props.index]);

  return (
    <div key={props.index} onClick={() => setIsOpen(!isOpen)} className="question cursor-pointer rounded-sm bg-white p-6 lg:p-8 transition-all">
      <div className="flex justify-between items-center">
        <h2 className="font-medium text-base lg:text-lg">{props.question}</h2>
        <svg className={isOpen ? 'rotate-0 w-[0.6rem] lg:w-auto': 'rotate-180 w-[0.6rem] lg:w-auto'} xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14">
          <path id="Polygon_4" data-name="Polygon 4" d="M8,0l8,14H0Z"/>
        </svg>
      </div>
      <p className="answer text-base lg:text-lg" open={isOpen}>{props.answer}</p>
    </div>
  );
}

export default Question;