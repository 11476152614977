const HowItWorks = () => {
  return (
    <div id="howitworks" className="section-grid my-8 lg:my-10 px-6 lg:px-0">
      <div className="lg:col-start-2 lg:col-span-11">
        <div className="bg-lightgrey rounded-2xl p-8 lg:p-24 lg:pr-0 grid grid-cols-1 lg:grid-cols-2 mr-0 lg:mr-[150px] gap-8">
          <div>
            <h2 className="font-medium text-4xl lg:text-6xl mb-8">How it works</h2> 
            <p className="text-xl md:text-2xl text-offblack my-8">Typesmith uses cutting-edge AI technology to craft unique, SEO-tailored product descriptions or ad copy for ecommerce stores in a matter of minutes.</p>
            <p className="my-4 text-[#777777]">Our custom AI analyses product titles and attributes to generate product descriptions, product titles and more - it can even take your brand’s tone of voice and target audience into consideration.</p>
            <p className="my-4 text-[#777777]">We’ll work with your team to discuss your brand, target customer and product inventory then set up a collaborative spreadsheet. We’ll then take our learnings and use our AI to create your copy before uploading it to your Shopify store. All we need from you is a CSV containing your product titles and key attributes.</p>
          </div>
          <div className="relative flex items-center">
            <img src="../../assets/seo.svg" alt="SEO Graphic" loading="lazy" className="ml-0 lg:ml-[10%] xl:ml-[17%]" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowItWorks;